@import '../../../index.css';
@import '../../../style.scss';

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__size {
    font-family: var(--modal-font, $modal-font);
    font-weight: 700;

    &--available {
      color: black;
    }

    &--unavailable {
      color: #D9D9D9;
    }
  }

  &__list {
    &__card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: 100%;
      transition: background-color ease 0.2s;

      &--active {
        border: 1px solid #000;
        background-color: #D9D9D9;

        & .MuiLinearProgress-bar {
          background-color: #000;
        }
      }

      &--inactive {
        border: 1px solid #000;
        background-color: white;

        & .MuiLinearProgress-bar {
          background-color: #686868;
        }

        &:hover {
          background-color: #f1f1f1;
        }
      }

      &--disabled {
        border: 1px solid #000;
        background-color: white;

        & .MuiLinearProgress-bar {
          background-color: #686868;
        }
      }

      &__selector {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 2px;

        &__child {
          background-color: black;
          border: 1px solid transparent;
          border-radius: 50%;
          height: 100%;
          width: 100%;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__header {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;

          &__size {
            font-family: var(--modal-font, $modal-font);
            font-weight: 700;
            text-align: left;
            width: 100%;
          }

          &__satisfaction {
            white-space: pre-line;
            font-family: var(--modal-font, $modal-font);
            font-weight: 700;
            text-align: right;
            width: 300%;
            //color: #959595;
            color: black;
          }
        }

        &__progress {
          &.MuiLinearProgress-root {
            background-color: #ACACAC;
            border-radius: 50px;
          }

          & .MuiLinearProgress-bar {
            border-radius: 50px;
          }
        }
      }
    }
  }

  &__text {
    &__description, &__hint, &__fail {
      color: black;
      text-align: center;
      font-family: var(--modal-font, $modal-font);
      font-weight: 400;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__save {
      font-family: var(--modal-font, $modal-font);
      font-weight: 800;
      text-transform: none;
      background-color: var(--cta-color, $cta-color);
      border-radius: var(--cta-border-radius, $cta-border-radius);
      color: white;
      transition: opacity ease 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }

    &__products {
      font-family: var(--modal-font, $modal-font);
      border: 2px solid #000;
      font-weight: 800;
      text-transform: none;
      background-color: transparent;
      border-radius: var(--cta-border-radius, $cta-border-radius);
      color: black;

      &:hover {
        background-color: black;
        color: white;
      }
    }

    &__text {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;

      &__restart {
        color: #898989;
        text-align: center;
        font-family: var(--modal-font, $modal-font);
        font-weight: 400;
        padding: 0;
        text-align: left;
        background-color: none;
        text-transform: none;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }

      &__products {
        color: #898989;
        text-align: center;
        font-family: var(--modal-font, $modal-font);
        font-weight: 400;
        padding: 0;
        text-align: left;
        background-color: none;
        text-transform: none;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    justify-content: center;

    &__size {
      line-height: 100px;
      margin-bottom: 40px;
      &--full {
        font-size: 150px;
      }
      &--reduce {
        font-size: 50px;
        letter-spacing: -3px;
      }
    }

    &__list {
      width: 400px;

      &__card:not(:last-child) {
        margin-bottom: 15px;
      }

      &__card {
        padding: 12px 16px;

        &__selector {
          border: 2px solid #7E7E7E;
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          margin: 0 16px 0 0;
        }

        &__content {
          &__header {
            &__size {
              font-size: 16px;
            }

            &__satisfaction {
              width: auto;
              min-width: 150px;
              font-size: 14px;
            }
          }

          &__progress {
            &.MuiLinearProgress-root {
              height: 8px;
            }
          }
        }
      }
    }

    &__text {
      width: 400px;

      &__description {
        margin: 40px 0 40px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }

      &__hint {
        margin: 0 0 15px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }

      &__fail {
        margin: 30px 0 30px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__buttons {
      width: 400px;

      &__save {
        font-size: 14px;
        height: 55px;
        width: 100%;
      }

      &__products {
        font-size: 14px;
        height: 55px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 40px;
        gap: 60px;

        &__restart {
          font-size: 14px;
          margin-top: 10px;
        }

        &__products {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    padding-top: 40px;

    &__size {
      text-align: center;
      &--full {
        font-size: 140px;
        margin-bottom: 40px;
      }
      &--reduce {
        font-size: 38px;
        letter-spacing: -3px;
        margin-bottom: 30px;
      }
      line-height: 76px;
    }

    &__list {
      width: 340px;

      &__card:not(:last-child) {
        margin-bottom: 15px;
      }

      &__card {
        padding: 10px 12px;

        &__selector {
          border: 1.5px solid #7E7E7E;
          min-width: 18px;
          min-height: 18px;
          width: 18px;
          height: 18px;
          margin: 0 12px 0 0;
        }

        &__content {
          &__header {
            &__size {
              font-size: 16px;
            }

            &__satisfaction {
              width: auto;
              min-width: 150px;
              font-size: 14px;
            }
          }

          &__progress {
            &.MuiLinearProgress-root {
              height: 7px;
            }
          }
        }
      }
    }

    &__text {
      width: 350px;

      &__description {
        margin: 40px 0 40px 0;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }

      &__hint {
        margin: 0 0 20px 0;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }

      &__fail {
        margin: 25px 0 25px 0;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__buttons {
      width: 350px;

      &__save {
        font-size: 12px;
        height: 50px;
        width: 100%;
      }

      &__products {
        font-size: 12px;
        height: 50px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 30px;
        gap: 50px;

        &__restart {
          font-size: 12px;
          margin-top: 10px;
        }

        &__products {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {
    padding-top: 30px;

    &__size {
      &--full {
        font-size: 120px;
      }
      &--reduce {
        font-size: 30px;
        letter-spacing: -3px;
      }
      line-height: 76px;
      margin-bottom: 30px;
    }

    &__list {
      width: 300px;

      &__card:not(:last-child) {
        margin-bottom: 10px;
      }

      &__card {
        padding: 10px 12px;

        &__selector {
          border: 1px solid #7E7E7E;
          min-width: 16px;
          min-height: 16px;
          width: 16px;
          height: 16px;
          margin: 0 12px 0 0;
        }

        &__content {
          &__header {
            &__size {
              font-size: 12px;
            }

            &__satisfaction {
              font-size: 12px;
            }
          }

          &__progress {
            &.MuiLinearProgress-root {
              height: 6px;
            }
          }
        }
      }
    }

    &__text {
      width: 300px;

      &__description {
        margin: 30px 0 30px 0;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
      }

      &__hint {
        margin: 0 0 10px 0;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
      }

      &__fail {
        margin: 20px 0 20px 0;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__buttons {
      width: 300px;

      &__save {
        font-size: 10px;
        height: 45px;
        width: 100%;
      }

      &__products {
        font-size: 10px;
        height: 45px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 25px;
        gap: 30px;

        &__restart {
          font-size: 10px;
          margin-top: 10px;
        }

        &__products {
          font-size: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
